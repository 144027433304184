import {
  Button,
  Col,
  Row,
  Form,
  Input,
  Select,
  Checkbox,
  message,
  Upload,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import AxiosService from "../../../utils/Config/ApiService";
import { useFormik } from "formik";
import * as Yup from "yup";
import SupportLoader from "../../../utils/Config/SupportLoader";
import axios from "axios";
import { ApiRoutes } from "../../../utils/Config/ApiRoutes";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

function AddEmployee() {
  //roles
  const [makesale, setmakesale] = useState(false);
  const [showDis, setshowDis] = useState(false);
  const [designation, setDesignation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [empRolesArr, setempRolesArr] = useState([])
  const [loading, setLoading] = useState(false);
  const [countries, setcountries] = useState([]);
  const [allcountries, setallcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [localAreas, setlocalareas] = useState([]);
  const [staff, setstaff] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const [isloader, setisloader] = useState(false);
  const [subloader, setsubloader] = useState(false);
  const [constStates, setConstStates] = useState([]);
  const [constCities, setConstCities] = useState([]);
  const [constLocalArea, setConstLocalAreas] = useState([]);
  const [roleList, setroleList] = useState([
    {
      roleId: 20,
      code: "SALE",
      Description: "Make A Sale",
      parentRoleId: 19,
      createDt: "2015-04-12 18:21:36",
      lastModifiedDt: "2015-04-12 18:21:36",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 0,
    },
    {
      roleId: 21,
      code: "MPR",
      Description: "Manage product",
      parentRoleId: 19,
      createDt: "2015-04-12 18:21:36",
      lastModifiedDt: "2015-04-12 18:21:36",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 0,
    },
    {
      roleId: 60,
      code: "store",
      Description: "My Store",
      parentRoleId: 19,
      createDt: null,
      lastModifiedDt: null,
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 0,
    },
    {
      roleId: 81,
      code: "PRODUCTRETURN",
      Description: "Make A Return",
      parentRoleId: 19,
      createDt: "2020-06-17 00:00:00",
      lastModifiedDt: "2020-06-17 00:00:00",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 0,
    },
    {
      roleId: 106,
      code: "GRANT_WEB_SIGN_IN",
      Description: "Grant Web Sign-in",
      parentRoleId: null,
      createDt: "2022-12-10 00:00:00",
      lastModifiedDt: "2022-12-10 00:00:00",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 1,
    },
    {
      roleId: 107,
      code: "TIME_CLOCK_ADMIN",
      Description: "Time Clock Admin",
      parentRoleId: null,
      createDt: "2022-12-18 00:00:00",
      lastModifiedDt: "2022-12-18 00:00:00",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 1,
    },
    {
      roleId: 108,
      code: "TIME_CLOCK_STAFF",
      Description: "Time Clock Staff",
      parentRoleId: null,
      createDt: "2022-12-18 00:00:00",
      lastModifiedDt: "2022-12-18 00:00:00",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 1,
    },
    {
      roleId: 109,
      code: "GEO_LOCK",
      Description: "Geo lock",
      parentRoleId: null,
      createDt: "2022-12-30 00:00:00",
      lastModifiedDt: "2022-12-30 00:00:00",
      lastModifiedBy: null,
      active: 1,
      isShowIncomm: 1,
    },
  ]);
  const [empRoles, setempRoles] = useState([]);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const updatedBy = userData.employeeId;
  const { storeid } = useParams();


  const history = useHistory();

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleSearch = (value, constStateVariables, setState) => {
    //search fn for select

    if (value?.length > 0 && value !== null) {
      const filteredData = constStateVariables.filter((state) =>
        state.label.toLowerCase().includes(value.toLowerCase())
      );
      return setState(filteredData);
    } else {
      return setState(constStateVariables);
    }
  };

  const handleGetDesignation = async () => {
    try {
      let res = await AxiosService.post(`${ApiRoutes.STAFF_DESIGNATION.service}${ApiRoutes.STAFF_DESIGNATION.url}`, {
        empRoles: empRoles,
        organizationId: Number(storeid),
      });

      if (res.status === 200) {
        setDesignation(res.data.designation);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyRoleCount = () => {
    const empRolesArr = [...empRoles];
    const fifteenthVal = empRolesArr.indexOf(15);
    if (fifteenthVal > -1) {
      empRolesArr.splice(fifteenthVal, 1);
    }

    const nineteenthVal = empRolesArr.indexOf(19);
    if (nineteenthVal > -1) {
      empRolesArr.splice(nineteenthVal, 1);
    }

    return empRolesArr.length;
  };

  const handleAddStaff = async (values) => {
    try {
      setsubloader(true)
      if (verifyRoleCount() > 0) {
        if (designation !== "") {
          const formData = new FormData();

          formData.append("firstName", values?.firstName);
          formData.append("lastName", values?.lastName);
          formData.append("email", values?.email ? values?.email : "" );
          formData.append("businessPhone", values?.businessPhone ? values?.businessPhone : "");
          formData.append("businessPhoneCode", staff?.businessPhoneCode);
          formData.append("userName", values.userName);
          formData.append("countryId", staff?.country ? staff?.country : sessionStorage.getItem('storeCouId') );
          formData.append("stateId", staff?.state );
          formData.append("cityId", staff?.city );
          formData.append("localArea", staff?.localArea );
          formData.append("zip", values?.zip ? values?.zip : "" );
          formData.append("addressLine1", values?.addressLine1 ? values?.addressLine1 : "" );
          formData.append(
            "isAllowDiscount",
            staff?.isAllowDiscount ? staff?.isAllowDiscount : ""
          );
          formData.append(
            "saleItemPermission",
            staff?.saleItemPermission ? staff?.saleItemPermission : ""
          );
          formData.append("designation", designation);
          formData.append("updatedBy", updatedBy);
          formData.append("employeeId", updatedBy);

          let wantedRoles = "[" + empRoles.join(",") + "]"; //send array as a whole
          formData.append("role", wantedRoles);
          //  empRoles.forEach((role) => formData.append("role[]", role))
          formData.append("organizationId", Number(storeid) / 55);

          let res = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/apiv11/manageEmployee/addEmployeeSupport`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Basic ${process.env.REACT_APP_PHP_Auth_Token}`,
              },
            }
          );

          if (res.data.success === 1) {
            let data = { 
              "organizationId":Number(storeid),
              "title":"Staff Added",
              "notes":`New staff ${values?.firstName} ${values?.lastName} added`,
              "createdBy": Number(userData['employeeId'])
          }
          let notesRes = await AxiosService.post(`${ApiRoutes.STORE_NOTES.service}${ApiRoutes.STORE_NOTES.url}`,data )
          if(notesRes.status === 200){
              form.resetFields();
              alert("Staff added Successfully");
              history.goBack();
              setsubloader(false);
          }

          } else if (res.data.success === 2) {
            let errors = "";
            Object.values(res.data.response.message).forEach((msg) => {
              errors = errors + msg + "\n";
            });
            alert(errors);
            setsubloader(false);
          } else {
            alert("Staff added failed");
            setsubloader(false);
          }
        } else {
          alert("Please add designation");
          setsubloader(false);
        }
      } else {
        alert("Please select aleast one role");
        setsubloader(false);
      }
    } catch (error) {
      console.log(error);
      setsubloader(false);
    }
  };
  const handleGetCountries = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.COUNTRY.service}${ApiRoutes.COUNTRY.url}`);
      if (res.data.statusCode === 200) {
        setallcountries(res.data.data);
        
          res.data.data?.forEach((ele) => {
            if(ele?.countryId == sessionStorage.getItem("storeCouId")){
              
              setstaff({
                ...staff,businessPhoneCode : ele?.phoneCode
              })
            }
          
          })
        
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [form] = Form.useForm();
  const handleGetStates = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.STATE.service}${ApiRoutes.STATE.url}/${id}`);
      if (res.data.statusCode === 200) {
        setstates([
          { value: "default", label: "Please select a state", disabled: true },
          ...res.data.data?.map((ele) => ({
            value: ele?.stateId,
            label: ele?.stateName,
          })),
        ]);
        setConstStates([
          ...res.data.data?.map((ele) => ({
            value: ele?.stateId,
            label: ele?.stateName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCities = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.CITY.service}${ApiRoutes.CITY.url}/${id}`);
      if (res.data.data.length > 0) {
        setcities([
          { value: "", label: "Please select a city", disabled: true },
          ...res.data.data?.map((ele) => ({
            value: ele?.cityId,
            label: ele?.cityName,
          })),
        ]);

        setConstCities([
          ...res.data.data?.map((ele) => ({
            value: ele?.cityId,
            label: ele?.cityName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetLocalAreas = async (id) => 
  {
    try {
      let res = await AxiosService.get(`${ApiRoutes.LOCALAREA.service}${ApiRoutes.LOCALAREA.url}/${id}`);

      if (res.data.data.length > 0) 
      {
        setlocalareas([
          { value: "", label: "Please select a Local Area", disabled: true },
          ...res.data.data?.map((ele) => ({
            value: ele?.id,
            label: ele?.areaName,
          })),
        ]);
        setConstLocalAreas([
          ...res.data.data?.map((ele) => ({
            value: ele?.id,
            label: ele?.areaName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCountryChange = (value) => {
    setstaff({
      ...staff,
      businessPhoneCode:
        "+" + (allcountries?.find((ele) => ele.countryId === value)).phoneCode,
      country: value,
      state: null,
      city: null,
    });
    form.setFieldsValue({
      businessPhoneCode:
        "+" + (allcountries?.find((ele) => ele.countryId === value)).phoneCode,
      stateName: "Please select a state",
      cityName: "Please select a city",
    });

    // form.setFields([
    //   {
    //     name: "stateName",
    //     value: "Please select a state",
    //   },
    // ]);
    // form.setFields([
    //   {
    //     name: "cityName",
    //     value: "Please select a city",
    //   },
    // ]);

    setSelectedCountry(value);

    setstates([]);
    handleGetStates(value);
  };

  useEffect(() => {
    // handleGetCountries();
    handleGetCountries()
    handleGetStates(sessionStorage.getItem("storeCouId"));
  }, []);

  const onFinish = (values) => {
    handleAddStaff(values);
  };

  const handleDefaultChecked = (id, text) => {
    return empRoles.some((roleId) => roleId === id);
  };

  useEffect(() => {
    if (empRoles.length) {
      handleGetDesignation();
    }
  }, [empRoles, storeid]);

  return (
    <>
      {isloader && staff?.businessPhoneCode ? (
        <div className="adminContentSection">
          <SupportLoader />
        </div>
      ) : (
        <div className="adminContentSection">
          <Row gutter={8}>
            <Col span={24}>
              <div className="displayFlex mt10 mb-1 spaceBetween">
                <Button
                  type="warning"
                  className="mr-3 btn--large d-flex align-items-center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              className="editProfileForm"
              style={{ width: "100%" }}
              initialValues={{
                country: sessionStorage.getItem("storeCouId"),
                countryName: sessionStorage.getItem("storeCouName"),
              }}
              onFinish={onFinish}
            >
              <Col span={16} offset={4}>
                <div className="profileDetailsRight bg-white mb-1">
                  <Row gutter={18}>
                    <Col span={24}>
                      <h4 className="mb-1">PERSONAL INFORMATION</h4>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter First Name",
                          },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        type="text"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Last Name",
                          },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Email",
                          },
                          {
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please Enter Valid Email",
                          },
                        ]}
                      >
                        <Input
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              email: value,
                            });
                          }}
                          placeholder="Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Mobile Phone"
                        name="businessPhone"
                        rules={[
                          {
                            pattern: /^[0-9]{10}$/,
                            message: "Please Enter Valid Phone Number",
                          },
                        ]}
                      >
                        <Input
                         
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              businessPhone: value,
                            });
                          }}
                          placeholder="Phone Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Username"
                        name="userName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Username",
                          },
                        ]}
                      >
                        <Input
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              userName: value.target.value,
                            });
                          }}
                          placeholder="Username"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="profileDetailsRight bg-white mb-1">
                  <Row gutter={18}>
                    <Col span={24}>
                      <h4 className="mb-1">ADDRESS INFORMATION</h4>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="State"
                        name="stateName"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select the state"
                          onSearch={(value) => {
                            handleSearch(value, constStates, setstates);
                          }}
                          showSearch={true}
                          filterOption={false}
                          options={states}
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              state: value,
                              city: null
                            });
                            handleSearch(null, constStates, setstates);
                           
                            handleGetCities(value);

                            form.setFieldsValue({
                              cityName: "Please select a city",
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="City"
                        name="cityName"
                      >
                        <Select
                          placeholder="Select the city"
                          style={{ width: "100%" }}
                          options={cities}
                          onSearch={(value) => {
                            handleSearch(value, constCities, setcities);
                          }}
                          showSearch={true}
                          filterOption={false}
                          onChange={(value) => {
                            handleSearch(null, constCities, setcities);
                            setstaff({
                              ...staff,
                              city: value,
                            });
                            handleGetLocalAreas(value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {sessionStorage.getItem("storeCouId") == 236 && (
                      <Col span={12}>
                        <Form.Item label="Local Area" name="localArea">
                          <Select
                            style={{ width: "100%" }}
                            options={localAreas}
                            onSearch={(value) => {
                              handleSearch(value, constLocalArea, setlocalareas);
                            }}
                            showSearch={true}
                            filterOption={false}
                            onChange={(value) => {
                              handleSearch(null, constLocalArea, setlocalareas);
                              setstaff({
                                ...staff,
                                localArea: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={12}>
                      <Form.Item label="Zipcode" name="zip">
                        <Input
                          placeholder="Zipcode"
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              zip: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Street Address" name="addressLine1">
                        <Input
                          placeholder="Please Enter Address"
                          onChange={(value) => {
                            setstaff({
                              ...staff,
                              addressLine1: value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="profileDetailsRight bg-white mb-1">
                  <Row gutter={18}>
                    <Col span={24}>
                      <h4 className="mb-1">ROLES</h4>
                    </Col>
                    <Col span={12}>
                      {/* <Form>
                      <Form.Item label="Street Address" name="addressLine1">
                        </Form.Item>
                      </Form> */}
                      <Input
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={18}>
                    <Col span={24} className="mb-1 custom--checkbox">
                      {roleList.length > 0 ? (
                        roleList?.map((role, i) => {
                          return (
                            <Checkbox
                              name={role?.Description}
                              // value={role?.roleId}
                              // defaultChecked={handleDefaultChecked(role?.roleId, role?.Description)}
                              onChange={(e) => {
                                const roleId = role?.roleId;
                                if (e.target.checked) {
                                  setempRoles([...empRoles, role?.roleId]);
                                  if (role?.Description === "Make A Sale") {
                                    setshowDis(true);
                                  }
                                } else {
                                  setempRoles(
                                    empRoles.filter((id) => id !== roleId)
                                  );
                                  if (role?.Description === "Make A Sale") {
                                    setshowDis(false);
                                    setstaff({
                                      ...staff,
                                      saleItemPermission: 0,
                                      isAllowDiscount: 0,
                                    });
                                  }
                                }
                              }}
                              key={i}
                            >
                              {role?.Description}
                            </Checkbox>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Col>

                    <br />
                    <br />
                    {showDis ? (
                      <>
                        <Col span={24}>
                          <h4 className="mb-1">DISCOUNT PERMISSION</h4>
                        </Col>
                        <Col span={24} className="mb-1 custom--checkbox">
                          <Checkbox
                            name="isAllowDiscount"
                            defaultChecked={staff?.isAllowDiscount === 1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setstaff({
                                  ...staff,
                                  isAllowDiscount: 1,
                                });
                              } else {
                                setstaff({
                                  ...staff,
                                  isAllowDiscount: 0,
                                });
                              }
                            }}
                          >
                            Cart Discount
                          </Checkbox>

                          <Checkbox
                            name="saleItemPermission"
                            defaultChecked={staff?.saleItemPermission === 1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setstaff({
                                  ...staff,
                                  saleItemPermission: 1,
                                });
                              } else {
                                setstaff({
                                  ...staff,
                                  saleItemPermission: 0,
                                });
                              }
                            }}
                          >
                            Sale item Permission
                          </Checkbox>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
                <div className="text-right mb-1">
                  <Button
                    type="primary"
                    className="mr-3 btn--large"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="warning"
                    htmlType="submit"
                    className="btn--large"
                  >
                    {subloader ? <SupportLoader /> : "Save"}
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
        </div>
      )}
    </>
  );
}

export default AddEmployee;
