export const messages = {
  "dashboard": {
      EN: "Dashboard",
      ES: "Tablero",
      FR: "Tableau de bord",
      PT: "Painel",
      GU: "ડેશબોર્ડ",
      ZH: "仪表板",
      GB: "Dashboard",
      GD: "deas-bhòrd",
      CY: "dangosfwrdd",
      GA: "deais"
  },
  "amount": {
      EN: "Amount",
      ES: "Monto",
      FR: "Montante",
      PT: "Resultar",
      GU: "રકમ",
      ZH: "数量",
      GB: "Amount",
      GD: "suim",
      CY: "swm",
      GA: "méid"
  },
  "salesSummary": {
      EN: "Sales Summary",
      ES: "Resumen de ventas",
      FR: "Résumé des ventes",
      PT: "Resumo de Vendas",
      GU: "વેચાણ સારાંશ",
      ZH: "销售概要",
      GB: "Sales Summary",
      GD: "reic Geàrr-chunntas",
      CY: "gwerthiantCrynodeb",
      GA: "díolacháinAchoimre"
  },
  "nonRevenueSummary": {
      EN: "Non Revenue Summary",
      ES: "Resumen de no ingresos",
      FR: "Sommaire des activités non commerciales",
      PT: "Resumo sem receita",
      GU: "બિન આવક સારાંશ ",
      ZH: "销售概要",
      GB: "Non Revenue Summary",
      GD: "neo-chunntas Geàrr-chunntas",
      CY: "nonRevenueCrynodeb",
      GA: "neamhAchoimre"
  },
  "itemSales": {
      EN: "Item Sales",
      ES: "Ventas de artículos",
      FR: "Ventes d'articles",
      PT: "Vendas de Itens",
      GU: "વસ્તુનું વેચાણ",
      ZH: "物品销售",
      GB: "Item Sales",
      GD: "stuth reic",
      CY: "EitemSales",
      GA: "Mír Díolacháin"
  },
  "nonRevenueItemSales": {
      EN: "Non Revenue Items",
      ES: "Ventas de artículos",
      FR: "Ventes d'articles",
      PT: "Vendas de Itens",
      GU: "વસ્તુનું વેચાણ",
      ZH: "物品销售",
      GB: "Non Revenue Items",
      GD: "Neo-reic stuth teachd-a-steach",
      CY: "Gwerthiannau Eitemau Refeniw",
      GA: "Díolacháin Míreanna Neamh-Rannóg"
  },
  "giftCards": {
      EN: "Gift Cards",
      ES: "Tarjetas de regalo",
      FR: "Cartes cadeaux",
      PT: "Cartões de presente",
      GU: "ભેટ માં આપવાના કાર્ડ્સ",
      ZH: "礼品卡",
      GB: "Gift Cards",
      GD: "Cairtean tiodhlac",
      CY: "Cardiau rhodd",
      GA: "Cártaí bronntanais"
  },
  "totalConsumed": {
      EN: "Total Consumed",
      ES: "Total consumido",
      FR: "Total consommé",
      PT: "Total Consumido",
      GU: "કુલ વપરાશ",
      ZH: "总消耗量",
      GB: "Total Consumed",
      GD: "iomlan air a chaitheamh",
      CY: "cyfanswm a fwyteir",
      GA: "iomlán arna gcaitheamh"
  },
  "totalActive": {
      EN: "Total Active",
      ES: "Total activo",
      FR: "Actif total",
      PT: "Totalmente Ativo",
      GU: "કુલ સક્રિય",
      ZH: "总活跃度",
      GB: "Total Active",
      GD: "iomlan Gnìomhach",
      CY: "cyfanswmActif",
      GA: "iomlánGníomhach"
  },
  "totalInactive": {
      EN: "Total Inactive",
      ES: "Total inactivo",
      FR: "Total inactif",
      PT: "Total Inativo",
      GU: "કુલ નિષ્ક્રિય",
      ZH: "完全不活跃",
      GB: "Total Inactive",
      GD: "iomlan neo-ghnìomhach",
      CY: "cyfanswm Anweithredol",
      GA: "iomlán Neamhghníomhach"
  },
  "totalCards": {
      EN: "Total Cards",
      ES: "Tarjetas totales",
      FR: "Total des cartes",
      PT: "Total de cartas",
      GU: "કુલ કાર્ડ્સ",
      ZH: "总牌数",
      GB: "Total Cards",
      GD: "Cairtean iomlan",
      CY: "Cyfanswm Cardiau",
      GA: "iomlánCártaí"
  },
  "productName": {
      EN: "Product Name",
      ES: "Nombre del producto",
      FR: "Nom du produit",
      PT: "Nome do Produto",
      GU: "ઉત્પાદન નામ",
      ZH: "产品名称",
      GB: "Product Name",
      GD: "Ainm toraidh",
      CY: "Enw cynnyrch",
      GA: "ainm táirge"
  },
  "cardNumber": {
      EN: "Card Number",
      ES: "Card Number",
      FR: "Numéro de carte",
      GB: "Card Number",
      GD: "Àireamh cairt",
      CY: "rhif cerdyn",
      GA: "uimhir chárta"
  },
  "TransactionAmount": {
      EN: "Transaction Amount",
      ES: "Transaction Amount",
      FR: "Montant de la transaction",
      GB: "Transaction Amount",
      GD: "TransactionSuim",
      CY: "Swm Trafodiad",
      GA: "TransactionMéid"
  },
  "Currency": {
      EN: "Currency",
      ES: "Currency",
      FR: "Devise",
      GB: "Currency",
      GD: "Airgeadra",
      CY: "Arian cyfred",
      GA: "Airgeadra"
  },
  "CardStatus": {
      EN: "Card Status",
      ES: "Card Status",
      FR: "Statut de la carte",
      GB: "Card Status",
      GD: "Inbhe cairt",
      CY: "Statws Cerdyn",
      GA: "Stádas Cárta"
  },
  "cardTypeDescription": {
      EN: "Transaction Type",
      ES: "Tipo",
      FR: "Taper",
      PT: "Tipo",
      GU: "પ્રકાર",
      ZH: "类型",
      GB: "Transaction Type",
      GD: "cairt SeòrsaDescription",
      CY: "cardTypeDescription",
      GA: "cártaCineálCur síos"
  },
  "fixedAmount": {
      EN: "Fixed Amount",
      ES: "Cantidad fija",
      FR: "Montant fixé",
      PT: "Quantia fixa",
      GU: "નિશ્ચિત રકમ",
      ZH: "固定值",
      GB: "Fixed Amount",
      GD: "Suim shuidhichte",
      CY: "Swm sefydlog",
      GA: "Méid seasta"
  },
  "minAmount": {
      EN: "Min Amount",
      ES: "Monto minimo",
      FR: "Montant minimal",
      PT: "Quantidade mínima",
      GU: "ન્યૂનતમ રકમ",
      ZH: "最低金额",
      GB: "Min Amount",
      GD: "min Meud",
      CY: "minSwm",
      GA: "minMéid"
  },
  "maxAmount": {
      EN: "Max Amount",
      ES: "Monto Máximo",
      FR: "Quantité maximale",
      PT: "Quantia máxima",
      GU: "મહત્તમ રકમ",
      ZH: "最高金额",
      GB: "Max Amount",
      GD: "Sùim as àirde",
      CY: "uchafswm",
      GA: "uasmhéid"
  },
  "isActive": {
      EN: "Active Status",
      ES: "Estado activo",
      FR: "Statut actif",
      PT: "Status Ativo",
      GU: "સક્રિય સ્થિતિ",
      ZH: "活跃状态",
      GB: "Active Status",
      GD: "tha Gnìomhach",
      CY: "yn Actif",
      GA: "is Gníomhach"
  },
  "isConsumed": {
      EN: "Consumed Status",
      ES: "Estado consumido",
      FR: "Statut consommé",
      PT: "Status Consumido",
      GU: "વપરાશની સ્થિતિ",
      ZH: "消费状态",
      GB: "Consumed Status",
      GD: "ga chaitheamh",
      CY: "yn cael ei fwyta",
      GA: "is Caitear"
  },
  "creationDate": {
      EN: "Created Date",
      ES: "Fecha de creación",
      FR: "Date de création",
      PT: "Data de criação",
      GU: "તારીખ બનાવી",
      ZH: "创建日期",
      GB: "Created Date",
      GD: "Ceann-latha cruthachaidh",
      CY: "dyddiad creu",
      GA: "cruthúDáta"
  },
  "activationDate": {
      EN: "Activation Date",
      ES: "Fecha de activacion",
      FR: "Date d'activation",
      PT: "Data de ativação",
      GU: "સક્રિયકરણ તારીખ",
      ZH: "激活日期",
      GB: "Activation Date",
      GD: "ceann-latha gnìomhachaidh",
      CY: "dyddiad actifadu",
      GA: "dáta gníomhachtaithe"
  },
  "transactionDate": {
      EN: "Transaction Date",
      ES: "Transaction Date",
      FR: "Date de transaction",
      GB: "Transaction Date",
      GD: "ceann-latha malairt",
      CY: "dyddiad trafodiad",
      GA: "idirbheartaDáta"
  },
  "disabledDate": {
      EN: "Disabled Date",
      ES: "Fecha de desactivación",
      FR: "Date de désactivation",
      PT: "Data de desativação",
      GU: "અક્ષમ તારીખ",
      ZH: "禁用日期",
      GB: "Disabled Date",
      GD: "ceann-latha ciorramach",
      CY: "anablDyddiad",
      GA: "díchumasaitheDáta"
  },
  "merchantManagement": {
      EN: "Merchant Management",
      ES: "Gestión de comerciantes",
      FR: "Gestion des marchands",
      PT: "Gestão de Comerciantes",
      GU: "મર્ચન્ટ મેનેજમેન્ટ",
      ZH: "商户管理",
      GB: "Merchant Management",
      GD: "ceannaiche-riaghlaidh",
      CY: "masnachwrRheolaeth",
      GA: "bainistíochta trádála"
  },
  "brokerManagement": {
      EN: "Broker Management",
      ES: "Gestión de corredores",
      FR: "Gestion des courtiers",
      PT: "Gestão da Corretora",
      GU: "બ્રોકર મેનેજમેન્ટ",
      ZH: "经纪人管理",
      GB: "Broker Management",
      GD: "Stiùireadh broker",
      CY: "brocerRheolaeth",
      GA: "bróicéir Bainistíochta"
  },
  "orderManagement": {
      EN: "Order Management",
      ES: "Gestión de pedidos",
      FR: "La gestion des commandes",
      PT: "Gestão de Pedidos",
      GU: "ઓર્ડર મેનેજમેન્ટ",
      ZH: "订单管理",
      GB: "Order Management",
      GD: "Riaghladh òrdugh",
      CY: "rheoli trefn",
      GA: "bainistíocht ordú"
  },
  "productManagement": {
      EN: "Product Management",
      ES: "Gestión de productos",
      FR: "Gestion des produits",
      PT: "Gestão de produtos",
      GU: "ઉત્પાદન વ્યવસ્થાપન",
      ZH: "产品管理",
      GB: "Product Management",
      GD: "riaghladh toraidh",
      CY: "rheoli cynnyrch",
      GA: "bainistíochta táirge"
  },
  "catalogManagement": {
      EN: "Catalog Management",
      ES: "Gestión de catálogos",
      FR: "Gestion de catalogue",
      PT: "Gestão de Catálogo",
      GU: "કેટલોગ મેનેજમેન્ટ",
      ZH: "目录管理",
      GB: "Catalog Management",
      GD: "riaghladh catalog",
      CY: "catalog rheolaeth",
      GA: "catalóg bainistíochta"
  },
  "groceryManagement": {
      EN: "Grocery Management",
      ES: "Gestión de abarrotes",
      FR: "Gestion d'épicerie",
      PT: "Gestão de Mercearia",
      GU: "ગ્રોસરી મેનેજમેન્ટ",
      ZH: "杂货管理",
      GB: "Grocery Management",
      GD: "Rianachd grosaireachd",
      CY: "rheoli groser",
      GA: "grósaeireacht Bainistíochta"
  },
  "employeeManagement": {
      EN: "Employee Management",
      ES: "Gestión de empleados",
      FR: "Gestion des employés",
      PT: "Gestão de Funcionários",
      GU: "કર્મચારી વ્યવસ્થાપન",
      ZH: "员工管理",
      GB: "Employee Management",
      GD: "Riaghladh luchd-obrach",
      CY: "rheoli gweithwyr",
      GA: "bainistíocht fostaithe"
  },
  "kardstashStoreQR": {
      EN: "Kardstash Store QR",
      ES: "Tienda Kardstash QR",
      FR: "QR du magasin Karstash",
      PT: "Kardstash Store QR",
      GU: "Kardstash સ્ટોર QR",
      ZH: "Kardstash 商店二维码",
      GB: "Kardstash Store QR",
      GD: "Stòr-dàta cairtQR",
      CY: "cardstashStoreQR",
      GA: "cardstashStoreQR"
  },
  "advertManagement": {
      EN: "Advert Management",
      ES: "Gestión de anuncios",
      FR: "Gestion des publicités",
      PT: "Gerenciamento de anúncios",
      GU: "એડવર્ટ મેનેજમેન્ટ",
      ZH: "广告管理",
      GB: "Advert Management",
      GD: "sanas-riaghlaidh",
      CY: "hysbysebRheoli",
      GA: "fógraíochtManagement"
  },
  "listing": {
      EN: "Listing",
      ES: "Listado",
      FR: "Référencement",
      PT: "Listagem",
      GU: "લિસ્ટિંગ",
      ZH: "清单",
      GB: "Listing",
      GD: "liostadh",
      CY: "rhestru",
      GA: "liostú"
  },
  "pendingList": {
      EN: "Pending List",
      ES: "Lista de pendientes",
      FR: "Liste en attente",
      PT: "Lista Pendente",
      GU: "બાકી યાદી",
      ZH: "待处理清单",
      GB: "Pending List",
      GD: "Liosta ri feitheamh",
      CY: "arfaethRhestr",
      GA: "ar feitheamhLiosta"
  },
  "financeManagement": {
      EN: "Finance Management",
      ES: "Gestión financiera",
      FR: "Gestion financière",
      PT: "Gestão Financeira",
      GU: "ફાયનાન્સ મેનેજમેન્ટ",
      ZH: "财务管理",
      GB: "Finance Management",
      GD: "riaghladh ionmhais",
      CY: "rheoli cyllid",
      GA: "bainistíocht airgeadais"
  },
  "receivables": {
      EN: "Receivables",
      ES: "Cuentas por cobrar",
      FR: "Créances",
      PT: "Recebíveis",
      GU: "પ્રાપ્તિપાત્ર",
      ZH: "应收账款",
      GB: "Receivables",
      GD: "cuidhteasan",
      CY: "symiau derbyniadwy",
      GA: "infhaighte"
  },
  "payables": {
      EN: "Payables",
      ES: "Cuentas a pagar",
      FR: "Dettes",
      PT: "Pagáveis",
      GU: "ચૂકવવાપાત્ર",
      ZH: "应付款",
      GB: "Payables",
      GD: "pàighidhean",
      CY: "symiau taladwy",
      GA: "nithe iníoctha"
  },
  "activityLog": {
      EN: "Activity Log",
      ES: "Registro de actividades",
      FR: "Journal d'activité",
      PT: "Registro de atividade",
      GU: "પ્રવૃત્તિ લોગ",
      ZH: "活动日志",
      GB: "Activity Log",
      GD: "Log gnìomhachd",
      CY: "gweithgareddLog",
      GA: "loga gníomhaíochta"
  },
  "ads": {
      EN: "Ads",
      ES: "Anuncios",
      FR: "Les publicités",
      PT: "Publicidades",
      GU: "જાહેરાતો",
      ZH: "广告",
      GB: "Ads",
      GD: "sanasan",
      CY: "hysbysebion",
      GA: "fógraí"
  },
  "allday": {
      EN: "All day",
      ES: "Todo el dia",
      FR: "Toute la journée",
      PT: "Dia todo",
      GU: "બધા દિવસ",
      ZH: "一整天",
      GB: "All day",
      GD: "fad an latha",
      CY: "trwy'r dydd",
      GA: "i rith an lae"
  },
  "summary": {
      EN: "Summary",
      ES: "Resumen",
      FR: "Résumé",
      PT: "Resumo",
      GU: "સારાંશ",
      ZH: "概括",
      GB: "Summary",
      GD: "geàrr-chunntas",
      CY: "crynodeb",
      GA: "achoimre"
  },
  "monthly": {
      EN: "Monthly",
      ES: "Mensual",
      FR: "Mensuelle",
      PT: "Por mês",
      GU: "માસિક",
      ZH: "每月",
      GB: "Monthly",
      GD: "mìosail",
      CY: "yn fisol",
      GA: "míosúil"
  },
  "weekly": {
      EN: "Weekly",
      ES: "Semanal",
      FR: "Hebdomadaire",
      PT: "Semanalmente",
      GU: "સાપ્તાહિક",
      ZH: "每周",
      GB: "Weekly",
      GD: "seachdaineil",
      CY: "wythnosol",
      GA: "seachtainiúil"
  },
  "daily": {
      EN: "Daily",
      ES: "Diario",
      FR: "du quotidien",
      PT: "Diariamente",
      GU: "દૈનિક",
      ZH: "日常的",
      GB: "Daily",
      GD: "gach latha",
      CY: "dyddiol",
      GA: "laethúil"
  },
  "dayOfWeek": {
      EN: "Day of week",
      ES: "Día de la semana",
      FR: "Jour de la semaine",
      PT: "Dia da semana",
      GU: "અઠવાડિયાનો દિવસ",
      ZH: "星期几",
      GB: "Day of week",
      GD: "latha den t-seachdain",
      CY: "diwrnodOwythnos",
      GA: "lá na Seachtaine"
  },
  "timeOfDay": {
      EN: "Time of day",
      ES: "Hora del día",
      FR: "Moment de la journée",
      PT: "Hora do dia",
      GU: "દિવસનો સમય",
      ZH: "一天中的时间",
      GB: "Time of day",
      GD: "àm Latha",
      CY: "amserOfDay",
      GA: "timeOfDay"
  },
  "summaryReport": {
      EN: "Summary Report",
      ES: "Informe resumido",
      FR: "Rapport sommaire",
      PT: "Relatório de Resumo",
      GU: "સારાંશ અહેવાલ",
      ZH: "总结报告",
      GB: "Summary Report",
      GD: "Geàrr-aithris",
      CY: "Adroddiad cryno",
      GA: "tuairisc achomair"
  },
  "sales": {
      EN: "Interval",
      ES: "Ventas",
      FR: "Ventes",
      PT: "Vendas",
      GU: "અંતરાલ",
      ZH: "间隔",
      GB: "Interval",
      GD: "reic",
      CY: "gwerthiannau",
      GA: "díolacháin"
  },
  "netSale": {
      EN: "Net Sale",
      ES: "Venta neta",
      FR: "Vente nette",
      PT: "Venda Líquida",
      GU: "નેટ સેલ",
      ZH: "净销售额",
      GB: "Net Sale",
      GD: "lìonSale",
      CY: "netSale",
      GA: "netSale"
  },
  "nonRevenueSale": {
      EN: "Non Revenue Sale",
      ES: "Venta sin ingresos",
      FR: "Vente sans revenus",
      PT: "Venda sem receita",
      GU: "બિન આવક વેચાણ",
      ZH: "非收入销售",
      GB: "Non Revenue Sale",
      GD: "neo-reic teachd-a-steach",
      CY: "di-Refeniw",
      GA: "neamhRevenue"
  },
  "totalNonRevenueItemsProcessed": {
      EN: "Total Non Revenue Item Processed",
      ES: "Total Non Revenue Item Processed",
      FR: "Total Non Revenue Item Processed",
      PT: "Total Non Revenue Item Processed",
      GU: "Total Non Revenue Item Processed",
      ZH: "Total Non Revenue Item Processed",
      GB: "Total Non Revenue Item Processed",
      GD: "gu h-iomlan nithean neo-ionmhasail air am pròiseasadh",
      CY: "CyfanswmEitemauDiRefeniw wedi'u Prosesu",
      GA: "totalNonRevenueItemsProcessed"
  },
  "mostFrequentNonRevenueItem": {
      EN: "Most Frequent Non Revenue",
      ES: "Total Non Revenue Item Processed",
      FR: "Total Non Revenue Item Processed",
      PT: "Total Non Revenue Item Processed",
      GU: "Total Non Revenue Item Processed",
      ZH: "Total Non Revenue Item Processed",
      GB: "Most Frequent Non Revenue",
      GD: "An nì as cumanta gun teachd a-steach",
      CY: "mwyafAmlNonRevenueItem",
      GA: "is CoitiantaNeamhMír Ioncaim"
  },
  "inventoryImpact": {
      EN: "Invenotry Impact",
      ES: "Invenotry Impact",
      FR: "Invenotry Impact",
      PT: "Invenotry Impact",
      GU: "Invenotry Impact",
      ZH: "Invenotry Impact",
      GB: "Invenotry Impact",
      GD: "buaidh clàr-seilbhe",
      CY: "effaith rhestr eiddo",
      GA: "Tionchar fardal"
  },
  "serviceUsageCount": {
      EN: "Service Usage Count",
      ES: "Service Usage Count",
      FR: "Service Usage Count",
      PT: "Service Usage Count",
      GU: "Service Usage Count",
      ZH: "Service Usage Count",
      GB: "Service Usage Count",
      GD: "seirbheisUsageCount",
      CY: "gwasanaethCyfrif Defnydd",
      GA: "serviceUsageCount"
  },
  "return": {
      EN: "Return",
      ES: "Regreso",
      FR: "Revenir",
      PT: "Retornar",
      GU: "પરત",
      ZH: "返回",
      GB: "Return",
      GD: "tilleadh",
      CY: "dychwelyd",
      GA: "filleadh"
  },
  "paymentCollected": {
      EN: "Payment Collected",
      ES: "Pago cobrado",
      FR: "Paiement perçu",
      PT: "Pagamento Recebido",
      GU: "ચુકવણી એકત્રિત કરી",
      ZH: "已收款",
      GB: "Payment Collected",
      GD: "pàigheadh air a chruinneachadh",
      CY: "taliadCasglwyd",
      GA: "íocaíochtBailithe"
  },
  "totalCollected": {
      EN: "Total Collected",
      ES: "Total recaudado",
      FR: "Total collecté",
      PT: "Total coletado",
      GU: "કુલ એકત્રિત",
      ZH: "总收集",
      GB: "Total Collected",
      GD: "iomlan air a chruinneachadh",
      CY: "cyfanswmCasglwyd",
      GA: "iomlánBailithe"
  },
  "returnAmt": {
      EN: "Return",
      ES: "Regreso",
      FR: "Revenir",
      PT: "Retornar",
      GU: "પરત",
      ZH: "返回",
      GB: "Return",
      GD: "tilleadhAmt",
      CY: "dychwelydAmt",
      GA: "aisAmt"
  },
  "cash": {
      EN: "Cash",
      ES: "Dinero en efectivo",
      FR: "En espèces",
      PT: "Dinheiro",
      GU: "રોકડ",
      ZH: "现金",
      GB: "Cash",
      GD: "airgead",
      CY: "arian parod",
      GA: "airgead tirim"
  },
  "card": {
      EN: "Card",
      ES: "Tarjeta",
      FR: "Carte",
      PT: "Cartão",
      GU: "કાર્ડ",
      ZH: "卡片",
      GB: "Card",
      GD: "cairt",
      CY: "cerdyn",
      GA: "cárta"
  },
  "wallet": {
      EN: "Wallet",
      ES: "Cartera",
      FR: "Portefeuille",
      PT: "Carteira",
      GU: "વૉલેટ",
      ZH: "钱包",
      GB: "Wallet",
      GD: "sporan",
      CY: "waled",
      GA: "sparán"
  },
  "other": {
      EN: "Other",
      ES: "Otra",
      FR: "Autre",
      PT: "Outra",
      GU: "અન્ય",
      ZH: "其他",
      GB: "Other",
      GD: "eile",
      CY: "arall",
      GA: "eile"
  },
  "customerInvoice": {
      EN: "Invoice",
      ES: "Invoice",
      FR: "Invoice",
      PT: "Invoice",
      GU: "Invoice",
      ZH: "Invoice",
      GB: "Invoice",
      GD: "fàirdeal teachdaiche",
      CY: "Anfoneb cwsmer",
      GA: "sonrasc custaiméara"
  },
  "tax": {
      EN: "Tax",
      ES: "Tax",
      FR: "Tax",
      PT: "Tax",
      GU: "Tax",
      ZH: "Tax",
      GB: "VAT",
      GD: "cìs",
      CY: "treth",
      GA: "cáin"
  },
  "CreditCardFee": {
      EN: "Credit Card Fee",
      ES: "Credit Card Fee",
      FR: "Credit Card Fee",
      PT: "Credit Card Fee",
      GU: "Credit Card Fee",
      ZH: "Credit Card Fee",
      GB: "Credit Card Fee",
      GD: "Cìs cairt-creideis",
      CY: "Ffi Cerdyn Credyd",
      GA: "TáilleCárta Creidmheasa"
  },
  "addReportingHours": {
      EN: "Add reporting hours",
      ES: "Agregar horas de informes",
      FR: "Ajouter des heures de rapport",
      PT: "Adicionar horas de relatório",
      GU: "રિપોર્ટિંગ કલાક ઉમેરો",
      ZH: "添加报告时间",
      GB: "Add reporting hours",
      GD: "cuir uairean aithris",
      CY: "ychwaneguOriau Adrodd",
      GA: "addReportingHours"
  },
  "save": {
      EN: "Save",
      ES: "Ahorrar",
      FR: "Sauvegarder",
      PT: "Salve ",
      GU: "સાચવો",
      ZH: "节省",
      GB: "Save",
      GD: "sàbhail",
      CY: "arbed",
      GA: "shábháil"
  },
  "cancel": {
      EN: "Cancel",
      ES: "Cancelar",
      FR: "Annuler",
      PT: "Cancelar",
      GU: "રદ કરો",
      ZH: "取消",
      GB: "Cancel",
      GD: "cuir dheth",
      CY: "canslo",
      GA: "ceal"
  },
  "selecttime": {
      EN: "Select Time",
      ES: "Seleccionar hora",
      FR: "Sélectionnez l'heure",
      PT: "Selecione o tempo",
      GU: "સમય પસંદ કરો",
      ZH: "选择时间",
      GB: "Select Time",
      GD: "àm taghaidh",
      CY: "amser dethol",
      GA: "am roghnaithe"
  },
  "shiftName": {
      EN: "Shift name",
      ES: "Nombre de turno",
      FR: "Nom de l'équipe",
      PT: "Nome do turno",
      GU: "શિફ્ટ નામ",
      ZH: "班次名称",
      GB: "Shift name",
      GD: "Ainm shift",
      CY: "shifftName",
      GA: "shiftName"
  },
  "shiftNameRequired": {
      EN: "Please enter shift name",
      ES: "Ingrese el nombre del turno",
      FR: "Veuillez saisir le nom de l'équipe",
      PT: "Por favor, insira o nome do turno",
      GU: "કૃપા કરીને શિફ્ટનું નામ દાખલ કરો",
      ZH: "请输入班次名称",
      GB: "Please enter shift name",
      GD: "shiftName a dhìth",
      CY: "shiftNameAngenrheidiol",
      GA: "shiftNameRiachtanach"
  },
  "shiftNamePlaceholder": {
      EN: "Enter shift name",
      ES: "Ingrese el nombre del turno",
      FR: "Entrez le nom de l'équipe",
      PT: "Insira o nome do turno",
      GU: "શિફ્ટ નામ દાખલ કરો",
      ZH: "输入班次名称",
      GB: "Enter shift name",
      GD: "shiftName Neach-àite",
      CY: "shifftNamePlaceholder",
      GA: "shiftNamePlaceholder"
  },
  "startTime": {
      EN: "Start time",
      ES: "Hora de inicio",
      FR: "Heure de début",
      PT: "Hora de início",
      GU: "પ્રારંભ સમય",
      ZH: "开始时间",
      GB: "Start time",
      GD: "àm tòiseachaidh",
      CY: "Amser cychwyn",
      GA: "Am tosaithe"
  },
  "startTimeRequired": {
      EN: "Please enter start time",
      ES: "Ingrese la hora de inicio",
      FR: "Veuillez saisir l'heure de début",
      PT: "Por favor, insira a hora de início",
      GU: "કૃપા કરીને પ્રારંભ સમય દાખલ કરો",
      ZH: "请输入开始时间",
      GB: "Please enter start time",
      GD: "Tòisich Ùine a dhìth",
      CY: "startTimeAngenrheidiol",
      GA: "startTimeRiachtanach"
  },
  "endTime": {
      EN: "End time",
      ES: "Hora de finalización",
      FR: "Heure de fin",
      PT: "Fim do tempo",
      GU: "સમાપ્તિ સમય",
      ZH: "时间结束",
      GB: "End time",
      GD: "crìoch-ùine",
      CY: "diweddAmser",
      GA: "Am deiridh"
  },
  "endTimeMessage": {
      EN: "Please enter end time",
      ES: "Ingrese la hora de finalización",
      FR: "Veuillez saisir l'heure de fin",
      PT: "Por favor, insira o horário de término",
      GU: "કૃપા કરીને સમાપ્તિ સમય દાખલ કરો",
      ZH: "请输入结束时间",
      GB: "Please enter end time",
      GD: "deireadhTimeMessage",
      CY: "endTimeMessage",
      GA: "deireadhTimeMessage"
  },
  "timezone": {
      EN: "Timezone",
      ES: "Zona horaria",
      FR: "Fuseau horaire",
      PT: "Fuso horário",
      GU: "સમય ઝોન",
      ZH: "时区",
      GB: "Timezone",
      GD: "raon-ama",
      CY: "cylchfa amser",
      GA: "crios ama"
  },
  "timezoneRequired": {
      EN: "Please enter timezone",
      ES: "Por favor ingrese la zona horaria",
      FR: "Veuillez saisir le fuseau horaire",
      PT: "Por favor, insira o fuso horário",
      GU: "કૃપા કરીને ટાઇમઝોન દાખલ કરો",
      ZH: "请输入时区",
      GB: "Please enter timezone",
      GD: "raon-ama a dhìth",
      CY: "parth amser Angenrheidiol",
      GA: "crios ama ag teastáil"
  },
  "timezonePlaceholder": {
      EN: "Select timezone",
      ES: "Selecciona la zona horaria",
      FR: "Sélectionnez le fuseau horaire",
      PT: "Selecione o fuso horário",
      GU: "ટાઇમઝોન પસંદ કરો",
      ZH: "选择时区",
      GB: "Select timezone",
      GD: "Neach-àite sòn ùine",
      CY: "amser parthDeiliad",
      GA: "timezonePlaceholder"
  },
  "selectLocation": {
      EN: "Select Location",
      ES: "Seleccionar ubicación",
      FR: "Sélectionnez l'emplacement",
      PT: "Selecione a localização",
      GU: "સ્થાન પસંદ કરો",
      ZH: "选择地点",
      GB: "Select Location",
      GD: "tagh Suidheachadh",
      CY: "dewis Lleoliad",
      GA: "roghnaigh Suíomh"
  },
  "Download": {
      EN: "Download",
      ES: "Descargar",
      FR: "Télécharger",
      PT: "Download",
      GU: "ડાઉનલોડ કરો",
      ZH: "下载",
      GB: "Download",
      GD: "Luchdaich sìos",
      CY: "Lawrlwythwch",
      GA: "Íosluchtaigh"
  },
  "Export": {
      EN: "Export",
      ES: "Exportar",
      FR: "Exportation",
      PT: "Exportar",
      GU: "નિકાસ કરો",
      ZH: "出口",
      GB: "Export",
      GD: "Às-mhalairt",
      CY: "Allforio",
      GA: "Easpórtáil"
  },
  "selectPaymentType": {
      EN: "Select Payment Type",
      ES: "Seleccione el tipo de pago",
      FR: "Sélectionnez le type de paiement",
      PT: "Selecione o tipo de pagamento",
      GU: "ચુકવણીનો પ્રકાર પસંદ કરો",
      ZH: "选择付款方式",
      GB: "Select Payment Type",
      GD: "tagh Seòrsa Pàigheadh",
      CY: "dewiswch MathTalu",
      GA: "roghnaighCineálPayment"
  },
  "all": {
      EN: "All",
      ES: "Todas",
      FR: "Tout",
      PT: "Tudo",
      GU: "બધા",
      ZH: "全部",
      GB: "All",
      GD: "uile",
      CY: "i gyd",
      GA: "go léir"
  },
  "emptyTitleWallet": {
      EN: "No Wallet Transaction History Available.",
      ES: "No hay historial de transacciones de billetera disponible.",
      FR: "Aucun historique des transactions de portefeuille disponible.",
      PT: "Nenhum histórico de transações da carteira disponível.",
      GU: "કોઈ વૉલેટ વ્યવહાર ઇતિહાસ ઉપલબ્ધ નથી.",
      ZH: "没有可用的钱包交易历史记录。",
      GB: "No Wallet Transaction History Available.",
      GD: "falamhTitleWallet",
      CY: "gwagTitleWallet",
      GA: "folamhTitleWallet"
  },
  "emptyTitleProductInventory": {
      EN: "Product Inventory Not Available",
      ES: "Inventaire de produits non disponible",
      FR: "Inventaire de produits non disponible",
      PT: "Nenhum histórico de transações da carteira disponível.",
      GU: "પ્રોડક્ટ ઇન્વેન્ટરી ઉપલબ્ધ નથી",
      ZH: "产品库存不可用",
      GB: "Product Inventory Not Available",
      GD: "falamhTitleProductInventory",
      CY: "gwagTitleProductInventory",
      GA: "folamhTitleProductInventory"
  },
  "emptyTitleNonRevenueItem": {
      EN: "Non Revenue Item Not Available",
      ES: "Artículo sin ingresos no disponible",
      FR: "IArtículo sin ingresos no disponible",
      PT: "Item sem receita não disponível",
      GU: "બિન આવક આઇટમ ઉપલબ્ધ નથી",
      ZH: "非收入项目不可用",
      GB: "Non Revenue Item Not Available",
      GD: "falamhTitleNonRevenueItem",
      CY: "gwagTitleNonRevenueItem",
      GA: "folamhTitleNonRevenueItem"
  },
  "emptyTitle": {
      EN: "No Sales in This Time Frame.",
      ES: "No hay ventas en este período de tiempo.",
      FR: "Aucune vente dans ce laps de temps.",
      PT: "Nenhuma venda neste período de tempo.",
      GU: "આ સમયની ફ્રેમમાં કોઈ વેચાણ નહીં.",
      ZH: "在此时间范围内没有销售。",
      GB: "No Sales in This Time Frame.",
      GD: "Tiotal falamh",
      CY: "gwagTeitl",
      GA: "folamhTitle"
  },
  "emptyDescription": {
      EN: "No sales took place during the time frame you selected.",
      ES: "No hubo ventas durante el período de tiempo que seleccionó.",
      FR: "Aucune vente n'a eu lieu pendant la période que vous avez sélectionnée.",
      PT: "Nenhuma venda ocorreu durante o período de tempo que você selecionou.",
      GU: "તમે પસંદ કરેલ સમયમર્યાદા દરમિયાન કોઈ વેચાણ થયું નથી.",
      ZH: "在您选择的时间范围内没有发生销售。",
      GB: "No sales took place during the time frame you selected.",
      GD: "falamhDescription",
      CY: "gwagDisgrifiad",
      GA: "folamhCur síos"
  },
  "quantity": {
      EN: "Quantity",
      ES: "Cantidad",
      FR: "Quantité",
      PT: "Quantidade",
      GU: "જથ્થો",
      ZH: "数量",
      GB: "Quantity",
      GD: "meud",
      CY: "maint",
      GA: "chainníocht"
  },
  "topFiveItems": {
      EN: "Top 5 Items",
      ES: "5 artículos principales",
      FR: "Top 5 des articles",
      PT: "5 itens principais",
      GU: "ટોચની 5 આઇટમ્સ",
      ZH: "前 5 项",
      GB: "Top 5 Items",
      GD: "topCòig nithean",
      CY: "topPumEitem",
      GA: "barrFiveItems"
  },
  "topTenItems": {
      EN: "Top 10 Items",
      ES: "10 artículos principales",
      FR: "Top 10 des articles",
      PT: "10 principais itens",
      GU: "ટોચની 10 આઇટમ્સ",
      ZH: "前 10 项",
      GB: "Top 10 Items",
      GD: "TopTenItems",
      CY: "topTenEitemau",
      GA: "barrTenItems"
  },
  "itemName": {
      EN: "Item name",
      ES: "Nombre del árticulo",
      FR: "Nom de l'article",
      PT: "Nome do item",
      GU: "વસ્તુનુ નામ",
      ZH: "项目名",
      GB: "Item name",
      GD: "ainm nì",
      CY: "Enw'r eitem",
      GA: "itemName"
  },
  "category": {
      EN: "Category",
      ES: "Categoría",
      FR: "Catégorie",
      PT: "Categoria",
      GU: "શ્રેણી",
      ZH: "类别",
      GB: "Category",
      GD: "roinn-seòrsa",
      CY: "categori",
      GA: "chatagóir"
  },
  "unitsSold": {
      EN: "Units sold",
      ES: "Unidades vendidas",
      FR: "Unités vendues",
      PT: "Unidades vendidas",
      GU: "એકમો વેચાયા",
      ZH: "售出单位",
      GB: "Units sold",
      GD: "aonadan air an reic",
      CY: "unedauSold",
      GA: "aonaidSold"
  },
  "salesAmount": {
      EN: "Sales amount",
      ES: "Cantidad de ventas",
      FR: "Montant des ventes",
      PT: "Total de vendas",
      GU: "વેચાણની રકમ",
      ZH: "销售额",
      GB: "Sales amount",
      GD: "meud reic",
      CY: "gwerthiantSwm",
      GA: "díolacháinMéid"
  },
  "Jan": {
      EN: "Jan",
      ES: "enero",
      FR: "janvier",
      PT: "Janeiro",
      GU: "જાન્યુ",
      ZH: "简",
      GB: "Jan",
      GD: "Faoilleach",
      CY: "Ion",
      GA: "Ean"
  },
  "Feb": {
      EN: "Feb",
      ES: "febrero",
      FR: "février",
      PT: "fevereiro",
      GU: "ફેબ્રુ",
      ZH: "ફેબ્રુ",
      GB: "Feb",
      GD: "Feb",
      CY: "Chwef",
      GA: "Feabh"
  },
  "Mar": {
      EN: "Mar",
      ES: "marcha",
      FR: "Mars",
      PT: "Março",
      GU: "માર",
      ZH: "三月",
      GB: "Mar",
      GD: "Mhàrr",
      CY: "Mar",
      GA: "Mar"
  },
  "Apr": {
      EN: "Apr",
      ES: "abril",
      FR: "avril",
      PT: "abril",
      GU: "એપ્રિલ",
      ZH: "4月",
      GB: "Apr",
      GD: "An Giblean",
      CY: "Ebr",
      GA: "Aibreán"
  },
  "May": {
      EN: "May",
      ES: "Mayo",
      FR: "Mai",
      PT: "Maio",
      GU: "મે",
      ZH: "可能",
      GB: "May",
      GD: "A’ Chèitean",
      CY: "Mai",
      GA: "Bealtaine"
  },
  "Jun": {
      EN: "Jun",
      ES: "junio",
      FR: "juin",
      PT: "Junho",
      GU: "જુન",
      ZH: "君",
      GB: "Jun",
      GD: "Jun",
      CY: "Meh",
      GA: "Meitheamh"
  },
  "Jul": {
      EN: "Jul",
      ES: "mes de julio",
      FR: "juillet",
      PT: "julho",
      GU: "જુલાઇ",
      ZH: "七月",
      GB: "Jul",
      GD: "Jul",
      CY: "Gorff",
      GA: "Iúil"
  },
  "Aug": {
      EN: "Aug",
      ES: "agosto",
      FR: "août",
      PT: "agosto",
      GU: "ઓગસ્ટ",
      ZH: "八月",
      GB: "Aug",
      GD: "Lùnastal",
      CY: "Awst",
      GA: "Lúnasa"
  },
  "Sep": {
      EN: "Sep",
      ES: "septiembre",
      FR: "septembre",
      PT: "setembro",
      GU: "સપ્ટે",
      ZH: "九月",
      GB: "Sep",
      GD: "Sep",
      CY: "Medi",
      GA: "Meán Fómhair"
  },
  "Oct": {
      EN: "Oct",
      ES: "octubre",
      FR: "octobre",
      PT: "Outubro",
      GU: "ઑક્ટો",
      ZH: "十月",
      GB: "Oct",
      GD: "Oct",
      CY: "Hyd",
      GA: "Deireadh Fómhair"
  },
  "Nov": {
      EN: "Nov",
      ES: "noviembre",
      FR: "novembre",
      PT: "novembro",
      GU: "નવે",
      ZH: "十一月",
      GB: "Nov",
      GD: "Nov",
      CY: "Tach",
      GA: "Samhain"
  },
  "Dec": {
      EN: "Dec",
      ES: "diciembre",
      FR: "décembre",
      PT: "dezembro",
      GU: "ડિસે",
      ZH: "十二月",
      GB: "Dec",
      GD: "Dec",
      CY: "Rhag",
      GA: "Dec"
  },
  "Yes": {
      EN: "Yes",
      ES: "sí",
      FR: "Oui",
      PT: "sim",
      GU: "હા",
      ZH: "是的",
      GB: "Yes",
      GD: "Tha",
      CY: "Oes",
      GA: "Tá"
  },
  "No": {
      EN: "No",
      ES: "No",
      FR: "Non",
      PT: "Não",
      GU: "ના",
      ZH: "不",
      GB: "No",
      GD: "Chan eil",
      CY: "Nac ydw",
      GA: "Níl"
  },
  "deleteReportingHoursMessage": {
      EN: "Are you sure to delete this reporting hours?",
      ES: "¿Estás seguro de eliminar este horario de informes?",
      FR: "Voulez-vous vraiment supprimer ces heures de rapport?",
      PT: "Tem certeza de que deseja excluir este horário de relatório?",
      GU: "શું તમે આ રિપોર્ટિંગ કલાકો કાઢી નાખવાની ખાતરી કરો છો?",
      ZH: "您确定要删除此报告时间吗？",
      GB: "Are you sure to delete this reporting hours?",
      GD: "sguab àsReportingHoursMessage",
      CY: "dileuReportingHoursMessage",
      GA: "scriosReportingHoursMessage"
  },
  "Delete": {
      EN: "Delete",
      ES: "Borrar",
      FR: "Effacer",
      PT: "Excluir",
      GU: "કાઢી નાખો",
      ZH: "删除",
      GB: "Delete",
      GD: "Sguab às",
      CY: "Dileu",
      GA: "Scrios"
  },
  "CopyRight": {
      EN: "Ekikart.All Rights Reserved",
      ES: "Ekikart.Todos los derechos reservados",
      FR: "Ekikart.Tous droits réservés",
      PT: "Ekikart.Todos os direitos reservados",
      GU: "Ekikart.સર્વ અધિકાર સુરક્ષિત",
      ZH: "Ekikart.保留所有权利",
      GB: "Ekikart.All Rights Reserved",
      GD: "CopyRight",
      CY: "Hawlfraint",
      GA: "Cóipcheart"
  },
  "Today": {
      EN: "Today",
      ES: "Hoy",
      FR: "Aujourd'hui",
      PT: "Hoje",
      GU: "આજે",
      ZH: "今天",
      GB: "Today",
      GD: "An-diugh",
      CY: "Heddiw",
      GA: "Inniu"
  },
  "Yesterday": {
      EN: "Yesterday",
      ES: "Ayer",
      FR: "Hier",
      PT: "Ontem",
      GU: "ગઇકાલે",
      ZH: "昨天",
      GB: "Yesterday",
      GD: "An-dè",
      CY: "Ddoe",
      GA: "Inné"
  },
  "thisWeek": {
      EN: "This Week",
      ES: "Esta semana",
      FR: "Cette semaine",
      PT: "Esta semana",
      GU: "આ અઠવાડિયે",
      ZH: "本星期",
      GB: "This Week",
      GD: "an t-seachdain seo",
      CY: "yr wythnos hon",
      GA: "an tseachtain seo"
  },
  "lastWeek": {
      EN: "Last Week",
      ES: "La semana pasada",
      FR: "La semaine dernière",
      PT: "Semana passada",
      GU: "ગયા સપ્તાહે",
      ZH: "上周",
      GB: "Last Week",
      GD: "An t-seachdan sa chaidh",
      CY: "wythnos diwethaf",
      GA: "tseachtain seo caite"
  },
  "thisMonth": {
      EN: "This Month",
      ES: "Este mes",
      FR: "Ce mois-ci",
      PT: "Este mês",
      GU: "આ મહિને",
      ZH: "这个月",
      GB: "This Month",
      GD: "am mìos seo",
      CY: "y Mis hwn",
      GA: "an mhí seo"
  },
  "lastMonth": {
      EN: "Last Month",
      ES: "El mes pasado",
      FR: "Le mois dernier",
      PT: "Mês passado",
      GU: "ગયા મહિને",
      ZH: "上个月",
      GB: "Last Month",
      GD: "mìos mu dheireadh",
      CY: "Mis diweddaf",
      GA: "an Mhí seo caite"
  },
  "thisYear": {
      EN: "This Year",
      ES: "Este año",
      FR: "Cette année",
      PT: "Este ano",
      GU: "આ વર્ષ",
      ZH: "今年",
      GB: "This Year",
      GD: "am-bliadhna",
      CY: "y flwyddyn hon",
      GA: "i mbliana"
  },
  "lastYear": {
      EN: "Last Year",
      ES: "El año pasado",
      FR: "L'année dernière",
      PT: "Ano passado",
      GU: "ગયું વરસ",
      ZH: "去年",
      GB: "Last Year",
      GD: "An-uiridh",
      CY: "y llynedd",
      GA: "anuraidh"
  },
  "noData": {
      EN: "No Data",
      ES: "Sin datos",
      FR: "Pas de données",
      PT: "Sem dados",
      GU: "કોઈ ડેટા નથી",
      ZH: "没有数据",
      GB: "No Data",
      GD: "noData",
      CY: "noData",
      GA: "noData"
  },
  "transactionSummary": {
      EN: "Transaction Summary",
      ES: "Resumen de Transacciones",
      FR: "récapitulatif des transactions",
      PT: "Resumo transação",
      GU: "વ્યવહાર સારાંશ",
      ZH: "交易概要",
      GB: "Transaction Summary",
      GD: "Geàrr-chunntas malairt",
      CY: "trafodiadCrynodeb",
      GA: "idirbheartAchoimre"
  },
  "transactionType": {
      EN: "Transaction Type",
      ES: "tipo de transacción",
      FR: "Type de transaction",
      PT: "Tipo de transação",
      GU: "વ્યવહારનો પ્રકાર",
      ZH: "交易类型",
      GB: "Transaction Type",
      GD: "Seòrsa malairt",
      CY: "Math o drafodiad",
      GA: "Cineál idirbheartaíochta"
  },
  "dateTime": {
      EN: "Date & Time",
      ES: "Fecha y hora",
      FR: "Date et heure",
      PT: "Data hora",
      GU: "તારીખ સમય",
      ZH: "约会时间",
      GB: "Date & Time",
      GD: "ceann-latha",
      CY: "dyddiadAmser",
      GA: "dátaAm"
  },
  "balance": {
      EN: "Balance",
      ES: "Equilibrio",
      FR: "Équilibre",
      PT: "Equilíbrio",
      GU: "સંતુલન",
      ZH: "平衡",
      GB: "Balance",
      GD: "cothromachadh",
      CY: "cydbwysedd",
      GA: "cothromaíocht"
  },
  "orderId": {
      EN: "Order ID",
      ES: "Solicitar ID",
      FR: "Numéro de commande",
      PT: "Id do pedido",
      GU: "ઓર્ડર આઈડી",
      ZH: "订单编号",
      GB: "Order ID",
      GD: "òrdughId",
      CY: "gorchymynId",
      GA: "ordúId"
  },
  "walletloaded": {
      EN: "Wallet loaded",
      ES: "Cartera cargada",
      FR: "Portefeuille chargé",
      PT: "Carteira carregada",
      GU: "વૉલેટ લોડ કર્યું",
      ZH: "钱包加载",
      GB: "Wallet loaded",
      GD: "walletloaded",
      CY: "walletloaded",
      GA: "sparán"
  },
  "paidForOrder": {
      EN: "Paid for order Id",
      ES: "Pagué por la orden que yo",
      FR: "Payé pour la commande, je le ferais",
      PT: "Pago pelo pedido que eu",
      GU: "ઓર્ડર આઈડી માટે ચૂકવણી",
      ZH: "支付订单ID",
      GB: "Paid for order Id",
      GD: "Òrdugh pàighte",
      CY: "taluForOrder",
      GA: "íocthaForOrder"
  },
  "cashback": {
      EN: "Cashback",
      ES: "Devolución de dinero",
      FR: "Remise en argent",
      PT: "Dinheiro de volta",
      GU: "પાછા આવેલા પૈસા",
      ZH: "返现金",
      GB: "Cashback",
      GD: "airgead air ais",
      CY: "arian yn ôl",
      GA: "aisairgead"
  },
  "organizationName": {
      EN: "Organization Name",
      ES: "Nombre de la Organización",
      FR: "nom de l'organisation",
      PT: "Nome da organização",
      GU: "સંસ્થા નુ નામ",
      ZH: "机构名称",
      GB: "Organization Name",
      GD: "Ainm buidheann",
      CY: "sefydliadName",
      GA: "eagraíochtAinm"
  },
  "EBT": {
      EN: "EBT",
      ES: "EBT",
      FR: "EBT",
      PT: "EBT",
      GU: "EBT",
      ZH: "EBT",
      GB: "EBT",
      GD: "EBT",
      CY: "EBT",
      GA: "EBT"
  },
  "customPayment": {
      EN: "Custom Payment",
      ES: "Pago personalizado",
      FR: "Paiement personnalisé",
      PT: "Pagamento personalizado",
      GU: "કસ્ટમ ચુકવણી",
      ZH: "自定义付款",
      GB: "Custom Payment",
      GD: "Custom Pàigheadh",
      CY: "arfer Taliad",
      GA: "saincheaptha Íocaíocht"
  },
  "productInventory": {
      EN: "Product Inventory",
      ES: "Inventario de productos",
      FR: "Inventaire des produits",
      PT: "Inventário de produtos",
      GU: "ઉત્પાદન ઈન્વેન્ટરી",
      ZH: "产品库存",
      GB: "Product Inventory",
      GD: "Clàr-stuthan toraidh",
      CY: "Stocrestr cynnyrch",
      GA: "Fardal táirgí"
  },
  "upc": {
      EN: "UPC\/PLU",
      ES: "UPC\/PLU",
      FR: "UPC\/PLU",
      PT: "UPC\/PLU",
      GU: "UPC\/PLU",
      ZH: "UPC\/PLU",
      GB: "UPC\/PLU",
      GD: "upc",
      CY: "upc",
      GA: "upc"
  },
  "inventoryCount": {
      EN: "Inventory",
      ES: "Inventario",
      FR: "Inventaire",
      PT: "Inventário",
      GU: "ઇન્વેન્ટરી",
      ZH: "存货",
      GB: "Inventory",
      GD: "Cunntas cunntais",
      CY: "cyfrif stocrestr",
      GA: "Áireamh fardail"
  },
  "costPrice": {
      EN: "Cost Price",
      ES: "Precio de costo",
      FR: "Prix coûtant",
      PT: "Preço de custo",
      GU: "પડતર કિંમત",
      ZH: "成本价",
      GB: "Cost Price",
      GD: "cosgaisPrice",
      CY: "costPris",
      GA: "costasPrice"
  },
  "sellPrice": {
      EN: "Sell Price",
      ES: "Precio de venta",
      FR: "Prix de vente",
      PT: "Preço de venda",
      GU: "વેચાણ કિંમત",
      ZH: "售价",
      GB: "Sell Price",
      GD: "reicPrice",
      CY: "gwerthuPrice",
      GA: "díolPrice"
  },
  "selectVendor": {
      EN: "Select vendor",
      ES: "Seleccionar proveedor",
      FR: "Choisir un fournisseur",
      PT: "Selecionar Fornecedor",
      GU: "વેન્ડર પસંદ કરો",
      ZH: "选择供应商",
      GB: "Select vendor",
      GD: "tagh Neach-reic",
      CY: "dewis Gwerthwr",
      GA: "roghnaigh Díoltóir"
  },
  "action": {
      EN: "Action",
      ES: "Acción",
      FR: "Action",
      PT: "Ação",
      GU: "ક્રિયા",
      ZH: "行动",
      GB: "Action",
      GD: "gnìomh",
      CY: "gweithred",
      GA: "gníomh"
  },
  "lowStock": {
      EN: "Low stock",
      ES: "Pocas existencias",
      FR: "Stock faible",
      PT: "Baixo estoque",
      GU: "ઓછો જથ્થો",
      ZH: "低库存",
      GB: "Low stock",
      GD: "Stoc ìosal",
      CY: "Stoc isel",
      GA: "Stoc íseal"
  },
  "alertAt": {
      EN: "Alert At",
      ES: "Alerta en",
      FR: "Alerte à",
      PT: "Alerta em",
      GU: "એલર્ટ મુ",
      ZH: "警报时间",
      GB: "Alert At",
      GD: "rabhadhAt",
      CY: "rhybuddAt",
      GA: "airdeallAt"
  },
  "editProductInventory": {
      EN: "Edit The Product Data",
      ES: "Editar los datos del producto",
      FR: "Modifier les données du produit",
      PT: "Editar os dados do produto",
      GU: "ઉત્પાદન ડેટા સંપાદિત કરો",
      ZH: "编辑产品数据",
      GB: "Edit The Product Data",
      GD: "deasaich an clàr-stuthan",
      CY: "editProductInventory",
      GA: "editProductInventory"
  },
  "lastUpdated": {
      EN: "Last Updated",
      ES: "Última actualización",
      FR: "Dernière mise à jour",
      PT: "Ultima atualização",
      GU: "છેલ્લું અપડેટ",
      ZH: "最近更新时间",
      GB: "Last Updated",
      GD: "air ùrachadh mu dheireadh",
      CY: "Diweddarwyd diwethaf",
      GA: "nuashonraithe"
  },
  "selectCategory": {
      EN: "Select Category",
      ES: "Selecciona una categoría",
      FR: "Choisir une catégorie",
      PT: "Selecione a Categoria",
      GU: "શ્રેણી પસંદ કરો",
      ZH: "选择类别",
      GB: "Select Category",
      GD: "tagh Roinn",
      CY: "dewisCategory",
      GA: "roghnaighCategory"
  },
  "selectSubCategory": {
      EN: "Select Sub Category",
      ES: "Seleccionar subcategoría",
      FR: "Sélectionnez la sous-catégorie",
      PT: "Selecione a subcategoria",
      GU: "શ્સબ કેટેગરી પસંદ કરો",
      ZH: "选择子类别",
      GB: "Select Sub Category",
      GD: "taghSubCategory",
      CY: "dewiswch Is-Gategori",
      GA: "roghnaighSubCategory"
  },
  "currentQty": {
      EN: "Current Qty",
      ES: "Cantidad actual",
      FR: "Quantité actuelle",
      PT: "Quantidade atual",
      GU: "વર્તમાન જથ્થો",
      ZH: "当前数量",
      GB: "Current Qty",
      GD: "gnàthachQty",
      CY: "presennolQty",
      GA: "reathaQty"
  },
  "alertAtQty": {
      EN: "Alert At Qty",
      ES: "Alerta en cantidad",
      FR: "Alerte à la quantité",
      PT: "Alerta na quantidade",
      GU: "પ્રમાણ પર ચેતવણી",
      ZH: "数量警报",
      GB: "Alert At Qty",
      GD: "rabhadh AtQty",
      CY: "rhybuddAtQty",
      GA: "airdeallAtQty"
  },
  "ok": {
      EN: "OK",
      ES: "OK",
      FR: "D'ACCORD",
      PT: "OK",
      GU: "પ્બરાબર",
      ZH: "好的",
      GB: "OK",
      GD: "ceart gu leòr",
      CY: "iawn",
      GA: "ceart go leor"
  },
  "page": {
      EN: "page",
      ES: "página",
      FR: "page",
      PT: "página",
      GU: "પાનું",
      ZH: "页",
      GB: "page",
      GD: "duilleag",
      CY: "tudalen",
      GA: "leathanach"
  },
  "sellPriceRequired": {
      EN: "Sell Price is required.",
      ES: "El precio de venta es obligatorio.",
      FR: "Le prix de vente est requis.",
      PT: "Preço de venda é obrigatório.",
      GU: "વેચાણ કિંમત જરૂરી છે.",
      ZH: "售价为必填项。",
      GB: "Sell Price is required.",
      GD: "reicPriceRequired",
      CY: "gwerthuPriceAngenrheidiol",
      GA: "sellPriceRiachtanach"
  },
  "sellPriceNumber": {
      EN: "Sell Price has to be a number.",
      ES: "El precio de venta tiene que ser un número.",
      FR: "Le prix de vente doit être un nombre.",
      PT: "O preço de venda deve ser um número.",
      GU: "વેચાણ કિંમત એક નંબર હોવો જોઈએ.",
      ZH: "售价必须是一个数字。",
      GB: "Sell Price has to be a number.",
      GD: "reicPriceNumber",
      CY: "gwerthuRhifPris",
      GA: "díolPriceNumber"
  },
  "costPriceNumber": {
      EN: "Cost Price has to be a number.",
      ES: "El precio de costo tiene que ser un número.",
      FR: "Le prix de revient doit être un nombre.",
      PT: "O preço de custo deve ser um número.",
      GU: "કિંમત કિંમત એક નંબર હોવો જોઈએ.",
      ZH: "成本价必须是一个数字。",
      GB: "Cost Price has to be a number.",
      GD: "àireamhPricePrice",
      CY: "costRhifPris",
      GA: "costasPriceNumber"
  },
  "alertAtNumber": {
      EN: "Alert At Qty has to be a number.",
      ES: "Alert At Qty tiene que ser un número.",
      FR: "L'alerte à la quantité doit être un nombre.",
      PT: "Alert At Qty tem que ser um número.",
      GU: "Alert At Qty એક નંબર હોવો જોઈએ.",
      ZH: "Alert At Qty 必须是一个数字。",
      GB: "Alert At Qty has to be a number.",
      GD: "rabhadh Aig Àireamh",
      CY: "rhybuddRhif",
      GA: "foláireamhAtNumber"
  },
  "currentQtyNumber": {
      EN: "Current Qty has to be a number.",
      ES: "La cantidad actual tiene que ser un número.",
      FR: "La quantité actuelle doit être un nombre.",
      PT: "A quantidade atual deve ser um número.",
      GU: "વર્તમાન જથ્થો એક નંબર હોવો જોઈએ.",
      ZH: "当前数量必须是一个数字。",
      GB: "Current Qty has to be a number.",
      GD: "ÀireamhQtyNumber",
      CY: "RhifQtyCyfredol",
      GA: "reathaQtyNumber"
  },
  "tip": {
      EN: "Tip",
      ES: "Consejo",
      FR: "Pointe",
      PT: "Dica",
      GU: "ટીપ",
      ZH: "小费",
      GB: "Tip",
      GD: "tip",
      CY: "tip",
      GA: "leid"
  },
  "timeClockManagement": {
      EN: "Time Clock Management",
      ES: "Gestión del reloj de tiempo",
      FR: "Gestion de l'horloge",
      PT: "Gerenciamento de relógio de ponto",
      GU: "સમય ઘડિયાળ વ્યવસ્થાપન",
      ZH: "时钟管理",
      GB: "Time Clock Management",
      GD: "Riaghladh timeClock",
      CY: "rheoli amserClock",
      GA: "timeClockManagement"
  },
  "nonRevenueItem": {
      EN: "Non Revenue Item",
      ES: "artículos no rentables",
      FR: "éléments non-revenus",
      PT: "éléments non-revenue",
      GU: "બિન આવક આઇટમ",
      ZH: "非收入项目",
      GB: "Non Revenue Item",
      GD: "nì neo-thorrach",
      CY: "Eitem diRefeniw",
      GA: "Mír na gCoimisinéirí Ioncaim"
  },
  "payroll": {
      EN: "Payroll",
      ES: "Gestión del reloj de tiempo",
      FR: "Gestion de l'horloge",
      PT: "Gerenciamento de relógio de ponto",
      GU: "સમય ઘડિયાળ વ્યવસ્થાપન",
      ZH: "时钟管理",
      GB: "Payroll",
      GD: "pàighidh",
      CY: "cyflogres",
      GA: "párolla"
  },
  "emptyTitleTimeClockManagement": {
      EN: "Time Clock Management Not Available",
      ES: "Gestión de reloj de tiempo no disponible",
      FR: "Gestion de l'horloge non disponible",
      PT: "Gerenciamento de relógio de ponto não disponível",
      GU: "સમય ઘડિયાળ વ્યવસ્થાપન ઉપલબ્ધ નથી",
      ZH: "时钟管理不可用",
      GB: "Time Clock Management Not Available",
      GD: "falamhTitleTimeClockManagement",
      CY: "emptyTitleTimeClockManagement",
      GA: "folamhTitleTimeClockManagement"
  },
  "employeeName": {
      EN: "Employee Name",
      ES: "Nombre de empleado",
      FR: "Nom de l'employé",
      PT: "Nome do empregado",
      GU: "કર્મચારીનું નામ",
      ZH: "员工姓名",
      GB: "Employee Name",
      GD: "Ainm neach-obrach",
      CY: "gweithiwrEnw",
      GA: "fostaíName"
  },
  "userPin": {
      EN: "User Pin",
      ES: "PIN de usuario",
      FR: "NIP de l'utilisateur",
      PT: "PIN do usuário",
      GU: "વપરાશકર્તા પિન",
      ZH: "用户密码",
      GB: "User Pin",
      GD: "cleachdaichePin",
      CY: "defnyddiwrPin",
      GA: "úsáideoirPin"
  },
  "clockIn": {
      EN: "Clock-in",
      ES: "Reloj en",
      FR: "Pointage",
      PT: "Hora de entrada",
      GU: "ઘડિયાળ-ઇન",
      ZH: "签到",
      GB: "Clock-in",
      GD: "gleoc a-steach",
      CY: "cloc Mewn",
      GA: "clogIn"
  },
  "clockOut": {
      EN: "Clock-out",
      ES: "Reloj de salida",
      FR: "Horloge",
      PT: "Hora de saída",
      GU: "ક્લોક-આઉટ",
      ZH: "下班",
      GB: "Clock-out",
      GD: "gleoc a-mach",
      CY: "cloc Allan",
      GA: "clogAmach"
  },
  "notes": {
      EN: "Notes",
      ES: "Notas",
      FR: "Remarques",
      PT: "Notas",
      GU: "નોંધો",
      ZH: "笔记",
      GB: "Notes",
      GD: "notaichean",
      CY: "nodiadau",
      GA: "nótaí"
  },
  "clockOutType": {
      EN: "Clock-In\/Clock-Out Type",
      ES: "Tipo de entrada\/salida",
      FR: "Type d'horloge d'entrée\/sortie",
      PT: "Tipo de entrada\/saída",
      GU: "ક્લોક-ઇન\/ક્લોક-આઉટ પ્રકાર",
      ZH: "上班\/下班类型",
      GB: "Clock-In\/Clock-Out Type",
      GD: "Seòrsa cloc",
      CY: "clocOutTip",
      GA: "clogAmachCineál"
  },
  "workingHours": {
      EN: "Working Hours",
      ES: "Horas Laborales",
      FR: "Heures d'ouverture",
      PT: "Jornada de trabalho",
      GU: "કામ નાં કલાકો",
      ZH: "工作时间",
      GB: "Working Hours",
      GD: "uairean obrach",
      CY: "oriau gwaith",
      GA: "Uaireanta oibre"
  },
  "breakHours": {
      EN: "Break Hours",
      ES: "Horas de descanso",
      FR: "Heures de pause",
      PT: "Horário de intervalo",
      GU: "બ્રેક અવર્સ",
      ZH: "休息时间",
      GB: "Break Hours",
      GD: "uairean briseadh",
      CY: "tororiau",
      GA: "sos Uaireanta"
  },
  "totalProductValue": {
      EN: "Total Product Value",
      ES: "Valor Total del Producto",
      FR: "Valeur Totale du Produit",
      PT: "Valor Total do Produto",
      GU: "કુલ ઉત્પાદ મૂલ્ય",
      ZH: "总产品价值",
      GB: "Total Product Value",
      GD: "Luach toraidh iomlan",
      CY: "CyfanswmGwerthCynnyrch",
      GA: "iomlánProductValue"
  },
  "dateRange": {
      EN: "Date Range",
      ES: "Rango de fechas",
      FR: "Plage de dates",
      PT: "Período",
      GU: "તારીખ શ્રેણી",
      ZH: "日期范围",
      GB: "Date Range",
      GD: "Raon-latha",
      CY: "dyddiadAmrediad",
      GA: "raon dáta"
  },
  "ratePerHour": {
      EN: "Salary Per Hour",
      ES: "Salario por hora",
      FR: "Salaire par heure",
      PT: "Salário por hora",
      GU: "કલાક દીઠ પગાર",
      ZH: "每小时工资",
      GB: "Salary Per Hour",
      GD: "ìre PerHour",
      CY: "cyfraddPerAwr",
      GA: "rátaPerHour"
  },
  "noOfHoursWorked": {
      EN: "No Of Hours Worked",
      ES: "Número de horas trabajadas",
      FR: "Nombre d'heures travaillées",
      PT: "Nº de horas trabalhadas",
      GU: "કામના કલાકોની સંખ્યા",
      ZH: "工作时间",
      GB: "No Of Hours Worked",
      GD: "chan eil uairean ag obair",
      CY: "dimOfOriauWedi Gweithio",
      GA: "noOfHoursOibrithe"
  },
  "totalWages": {
      EN: "Total Wages",
      ES: "Salarios totales",
      FR: "Salaires totaux",
      PT: "Salários totais",
      GU: "કુલ વેતન",
      ZH: "工资总额",
      GB: "Total Wages",
      GD: "tuarastal iomlan",
      CY: "cyfanswm Cyflogau",
      GA: "Pá iomlán"
  },
  "totalAmount": {
      EN: "Total Amount",
      ES: "Cantidad total",
      FR: "Montant total",
      PT: "Montante total",
      GU: "કુલ રકમ",
      ZH: "总金额",
      GB: "Total Amount",
      GD: "Suim iomlan",
      CY: "cyfanswmSwm",
      GA: "iomlánMéid"
  },
  "contractorName": {
      EN: "Contractor Name",
      ES: "El nombre del contratista",
      FR: "Nom de l'entrepreneur",
      PT: "Nome do contratante",
      GU: "કોન્ટ્રાક્ટરનું નામ",
      ZH: "承包商名称",
      GB: "Contractor Name",
      GD: "Ainm cunnradair",
      CY: "Enw contractwr",
      GA: "conraitheoirName"
  },
  "serviceRendered": {
      EN: "Service Rendered",
      ES: "Servicio prestado",
      FR: "Service rendu",
      PT: "Serviço prestado",
      GU: "સેવા પ્રદાન કરી",
      ZH: "服务呈现",
      GB: "Service Rendered",
      GD: "seirbheis air a thoirt seachad",
      CY: "gwasanaeth Wedi'i Rendro",
      GA: "seirbhís tugtha"
  },
  "percentage": {
      EN: "Percentage",
      ES: "Porcentaje",
      FR: "Pourcentage",
      PT: "Percentagem",
      GU: "ટકાવારી",
      ZH: "百分比",
      GB: "Percentage",
      GD: "ceudad",
      CY: "canran",
      GA: "céatadán"
  },
  "mailReport": {
      EN: "Mail Report",
      ES: "Informe por correo",
      FR: "Rapport de courrier",
      PT: "Relatório de correio",
      GU: "મેલ રિપોર્ટ",
      ZH: "邮件报告",
      GB: "Mail Report",
      GD: "Aithris puist",
      CY: "bostAdrodd",
      GA: "Tuairisc poist"
  },
"searchBy": {
    EN: "Search by: Item Name, UPC", 
    ES: "Buscar por: Nombre del artículo, UPC", 
    FR: "Rechercher par : Nom de l'article, UPC", 
    PT: "Pesquisar por: Nome do item, UPC", 
    GU: "આના દ્વારા શોધો: વસ્તુનું નામ, UPC", 
    ZH: "搜索依据：商品名称、UPC", 
    GB: "Search by: Item Name, UPC",
    GD: "lorg le: Item Name, UPC",
    CY: "chwilioGan: Item Name, UPC",
    GA: "cuardach le: Item Name, UPC"
  },
  "vatReturnReport": {
      EN: "VAT Return Report",
      ES: "Informe de devolución de IVA",
      FR: "Rapport de déclaration de TVA",
      PT: "Relatório de devolução do IVA",
      GU: "વેટ રિટર્ન રિપોર્ટ",
      ZH: "增值税申报报告",
      GB: "VAT Return Report", 
      GD: "Aithisg Tilleadh VAT",
      CY: "Adroddiad Dychwelyd TAW",
      GA: "Tuarascáil ar Thuairisceán CBL"
  }
};
