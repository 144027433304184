import React from "react";
import { Table } from "antd";
import { messages } from "../../../../utils/Common/Messages";
import { getUserData } from "../../../../utils/Helpers";

const Salessummarylist = (props) => {
  const dualPricing = getUserData()?.dualPricing ?? false;
  const { interval, dataSetSummaryReport, lan, currencySign, dataSetTaxSummaryReport, dataSetVoidSummaryReport } = props;
  const columns = [
    {
      title: messages.sales[lan],
      dataIndex: "label",
      key: "label",
      fixed: "left",
      width: 170,
    },
  ];
  const data = [];
  let netSale = {
    key: "Net Sale",
    label: [messages.netSale[lan]],
  };
  
  let nonRevenueSale = {
    key: "Non Revenue Sale",
    label: [messages.nonRevenueSale[lan]],
  };
  let returnSale = {
    key: "Return",
    label: [messages.return[lan]],
  };
  let voidAmt = {
    key: "void_amount",
    label: "Void Amount",
  }
  let shippingAmount = {
    key: "shippingAmount",
    label: "Shipping Amount",
  }

  let taxTotAmt = {
    key: "tax_total_amount",
    label: [messages.tax[lan]],
  }

  let tipAmt = {
    key: "tip_amount",
    label: "Tip",
  }

  let discount = {
    key: "totalDiscountAmount",
    label: "Discount"//tempory renaming to move the ticket as requested by gaurang
  }

  let cashDiscount = {
    key: "cashDiscountAmt",
    label: "Dual Pricing Discount"
  }
  let children = [];
  let keyChildren = [];
  if (dataSetTaxSummaryReport && dataSetTaxSummaryReport.length > 0) {
    dataSetTaxSummaryReport.forEach((value) => {
      console.log('value', value);
      if (keyChildren.indexOf(value.key) < 0) {
        // keyChildren.push(value.key);
        children.push({
          key: value.taxName,
          label: value.taxName
        });
      }

    });
  }

  let voidChildren = [];
  let voidkeyChildren = [];
  if (dataSetVoidSummaryReport && dataSetVoidSummaryReport.length > 0) {
    dataSetVoidSummaryReport.forEach((value) => {
      console.log('value', value);
      if (voidkeyChildren.indexOf(value.key) < 0) {
        voidChildren.push({
          key: value.voidType,
          label: value.voidType
        });
      }

    });
  }
  if (dataSetSummaryReport && dataSetSummaryReport.length > 0) {
    for (let i = 0; i < dataSetSummaryReport.length; i++) {
      columns.push({
        title:
          dataSetSummaryReport[i].interval_start !==
            dataSetSummaryReport[i].interval_end
            ? dataSetSummaryReport[i].interval_start +
            "-" +
            dataSetSummaryReport[i].interval_end
            : dataSetSummaryReport[i].interval_start,
        dataIndex: `dayfilterData${i + 1}`,
        key: i + 1,
        width: 150,
        align: "right",
      });

      netSale[`dayfilterData${i + 1}`] =
        dataSetSummaryReport[i].net_sale >= 0
          ? currencySign + dataSetSummaryReport[i].net_sale
          : "-" + currencySign + Math.abs(dataSetSummaryReport[i].net_sale);

          nonRevenueSale[`dayfilterData${i + 1}`] =
          dataSetSummaryReport[i].non_revenue_total >= 0
            ? currencySign + dataSetSummaryReport[i].non_revenue_total
            : "-" + currencySign + Math.abs(dataSetSummaryReport[i].non_revenue_total);
      returnSale[`dayfilterData${i + 1}`] =
        dataSetSummaryReport[i].return_amt >= 0
          ? currencySign + dataSetSummaryReport[i].return_amt
          : "-" + currencySign + Math.abs(dataSetSummaryReport[i].return_amt);


      voidAmt[`dayfilterData${i + 1}`] =
        dataSetSummaryReport[i].void_amount >= 0
          ? currencySign + dataSetSummaryReport[i].void_amount
          : "-" + currencySign + Math.abs(dataSetSummaryReport[i].void_amount);
        shippingAmount[`dayfilterData${i + 1}`] =
          dataSetSummaryReport[i].shippingAmount >= 0
            ? currencySign + dataSetSummaryReport[i].shippingAmount
            : "-" + currencySign + Math.abs(dataSetSummaryReport[i].shippingAmount);
          

      voidChildren.forEach((value, key) => {
        voidChildren[key][`dayfilterData${1}`] =
          currencySign +
          dataSetVoidSummaryReport
            .map((item) => (item.voidType === value.key ? Math.abs(item.voidAmount) : 0))
            .reduce((prev, next) => prev + next).toFixed(2)
      });
      if (voidChildren.length > 0) {
        voidAmt.children = voidChildren;
      }
      taxTotAmt[`dayfilterData${i + 1}`] =
        dataSetSummaryReport[i].totTaxAmount >= 0
          ? currencySign + dataSetSummaryReport[i].totTaxAmount
          : "-" + currencySign + Math.abs(dataSetSummaryReport[i].totTaxAmount);
      children.forEach((value, key) => {
        children[key][`dayfilterData${1}`] =
          currencySign +
          dataSetTaxSummaryReport
            .map((item) => (item.taxName === value.key ? Math.abs(item.productTax) : 0))
            .reduce((prev, next) => prev + next).toFixed(2)
      });
      if (children.length > 0) {
        taxTotAmt.children = children;
      }
      tipAmt[`dayfilterData${i + 1}`] =
        dataSetSummaryReport[i].tipAmount >= 0
          ? currencySign + dataSetSummaryReport[i].tipAmount
          : "-" + currencySign + Math.abs(dataSetSummaryReport[i].tipAmount);

      // if(children.length > 0){
      //   discount.children = children;
      // }  
      discount[`dayfilterData${i + 1}`] =
        dataSetSummaryReport[i].totalDiscountAmount >= 0 ? currencySign + Math.abs(dataSetSummaryReport[i].totalDiscountAmount).toFixed(2) :
          "-" + currencySign + Math.abs(dataSetSummaryReport[i].totalDiscountAmount).toFixed(2);

      // if(children.length > 0){
      //   cashDiscount.children = children;
      // }  
      if(dualPricing==="true" || dualPricing===true){
        cashDiscount[`dayfilterData${i + 1}`] =
          dataSetSummaryReport[i].cashDiscountAmt >= 0 ? currencySign + Math.abs(dataSetSummaryReport[i].cashDiscountAmt).toFixed(2) :
            "-" + currencySign + Math.abs(dataSetSummaryReport[i].cashDiscountAmt).toFixed(2);
      }

    }
  }


  data.push(netSale);
  data.push(returnSale);
  data.push(voidAmt);
  data.push(shippingAmount);
  
  data.push(taxTotAmt);
  data.push(tipAmt);
  data.push(discount);
  data.push(nonRevenueSale);
  if(dualPricing==="true" || dualPricing===true)
    data.push(cashDiscount);
  return (
    <Table
      locale={{ emptyText: messages.noData[lan] }}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={interval === "summary" || columns.length < 3 ? "" : { x: 1500 }}
      className="paymentcollectedTable"
    />
  );
};
export default Salessummarylist;
